// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeShareMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M24.9062 13.7445V27.9615H7.09375V13.7445M11.5469 10.072L16 5.80566M16 5.80566L20.4531 10.072M16 5.80566L16 21.2048"
      stroke="currentcolor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityStrokeShareMediumIcon;
