import React, { useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import SwankyStyleSwatch from 'components/productdetail/swankyswatch/SwankyStyleSwatch';
import { showAirplaneSizing } from 'helpers/AirplaneSeatSizing';
import { getSwatchLegends, isColorOosForSelectedSizing } from 'helpers/SwankySwatchUtils';
import useMartyContext from 'hooks/useMartyContext';
import type { FormattedProductBundle, ProductDetailState } from 'reducers/detail/productDetail';
import type { AppState } from 'types/app';
import type { ProductStyle } from 'types/cloudCatalog';
import type { AirplaneCache } from 'types/AirplaneCache';
import Legend from 'components/productdetail/swankyswatch/Legend';
import { Legends } from 'components/productdetail/swankyswatch/LegendIndicator';
import { selectIsFeaturePdpPaperCuts } from 'selectors/features';

import css from 'styles/components/productdetail/swankyswatch/SwankySwatchBox.scss';

type OnChange<T> = (e: React.ChangeEvent<T>) => void;

const INITIAL_COLLAPSED_STYLES = 12;

export interface GivenProps {
  isGiftCard: boolean;
  onColorChange: (styleId: string, colorName?: string, isNew?: string) => void;
  productState: ProductDetailState;
  airplaneCache?: AirplaneCache;
  productType: string;
  selectedStyle: ProductStyle;
  styleList: ProductStyle[];
  collapsable?: boolean;
  isHydraColorLegend?: boolean;
  sizingChooser?: React.ReactNode;
  pageType: string;
  shouldCheckStock?: boolean;
}

export type SwankySwatchBoxProps = GivenProps & ReturnType<typeof mapStateToProps>;

function SwankySwatchBox(props: SwankySwatchBoxProps) {
  const {
    collapsable = false,
    isGiftCard,
    onColorChange,
    selectedStyle,
    styleList,
    airplaneCache,
    isHydraColorLegend,
    sizingChooser,
    pageType
  } = props;
  const { styleId: selectedStyleId } = selectedStyle;
  const isPdpPaperCutsFeatureEnabled = useSelector(selectIsFeaturePdpPaperCuts);

  const onColorChangeForSwatches: OnChange<HTMLInputElement> = useCallback(
    e => {
      const {
        dataset: { colorName, styleId, isNew }
      } = e.target as HTMLInputElement;
      styleId && colorName && onColorChange(styleId, colorName, isNew);
    },
    [onColorChange]
  );

  const onColorChangeForDropdown: OnChange<HTMLSelectElement> = useCallback(
    e => {
      const { value } = e.target as HTMLSelectElement;
      if (value) {
        const newSelectedStyle = styleList.find(style => style.styleId === value);
        if (newSelectedStyle) {
          onColorChange(value, newSelectedStyle.color, newSelectedStyle.isNew);
        }
      }
    },
    [onColorChange, styleList]
  );

  let styleLabel = selectedStyle.specs ? 'Specs' : 'Color';
  let styleNamePrefix = '';
  if (isGiftCard) {
    styleLabel = 'Gift Amount';
    styleNamePrefix = '$';
  }

  const { testId } = useMartyContext();
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => setCollapsed(!collapsed);
  const legends = getSwatchLegends(styleList);
  const isShowLegend = legends.length > 0;

  return (
    <div className={cn({ [css.isCollapsed]: collapsable && collapsed })}>
      <div className={cn(isPdpPaperCutsFeatureEnabled ? 'mb-2' : 'mb-3')}>
        <span className={css.colorTextLabel}>{styleLabel}:</span>
        <span className={css.colorTextValue} data-test-id={testId('selectedColor')}>
          {styleNamePrefix}
          {selectedStyle.specs || selectedStyle.color}
        </span>
      </div>
      <div className={cn(isPdpPaperCutsFeatureEnabled ? 'mb-2' : 'mb-3')}>{sizingChooser}</div>
      {isHydraColorLegend && isShowLegend && <Legend legends={legends}></Legend>}
      <fieldset>
        <legend className="sr-only">Select A {styleLabel}</legend>
        {isGiftCard ? (
          <select
            id="pdp-color-select"
            name="styleId"
            className={css.dropdown}
            data-test-id={testId('pdp-color')}
            onChange={onColorChangeForDropdown}
            value={selectedStyleId}
          >
            {styleList.map(({ styleId, color, isNew }) => (
              <option data-color-name={color} data-style-id={styleId} data-is-new={isNew} key={styleId} value={styleId}>
                {styleNamePrefix}
                {color}
              </option>
            ))}
          </select>
        ) : (
          <div className={css.swatches} data-test-id={testId('styleSwatchContainer')}>
            {styleList.map(style => makeSwatch(props, airplaneCache, style, onColorChangeForSwatches, pageType))}
          </div>
        )}
      </fieldset>
      {collapsable && styleList.length > INITIAL_COLLAPSED_STYLES && (
        <button className={css.collapseLink} type="button" onClick={toggleCollapse}>
          See {collapsed ? 'more' : 'less'} {isGiftCard ? 'options' : 'colors'}
        </button>
      )}
    </div>
  );
}

export function makeSwatch(
  props: SwankySwatchBoxProps,
  airplaneCache: AirplaneCache | undefined,
  style: ProductStyle,
  onColorChangeForSwatches: OnChange<HTMLInputElement>,
  pageType: string
) {
  const { airplaneAllowList, alwaysAllowAirplane, productState, productType, selectedStyle, isHydraColorLegend, shouldCheckStock = true } = props;

  const { detail: { brandName, productId } = {} as FormattedProductBundle } = productState;
  const { colorId, styleId, isNew } = style;

  let isInStock;
  if (shouldCheckStock) {
    if (showAirplaneSizing(airplaneAllowList, alwaysAllowAirplane, productType, airplaneCache)) {
      isInStock = airplaneCache.available.colorOptions.includes(colorId);
    } else {
      isInStock = !isColorOosForSelectedSizing(colorId, productState);
    }
  }

  return (
    <SwankyStyleSwatch
      key={styleId}
      isSelected={styleId === selectedStyle.styleId}
      isUnavailable={shouldCheckStock && !isInStock}
      onColorChange={onColorChangeForSwatches}
      productType={productType}
      style={style}
      brandName={brandName}
      namespace={`${productId}-${pageType}`}
      legendIndicator={isHydraColorLegend && isNew === 'true' ? Legends.New : undefined}
    />
  );
}

function mapStateToProps(store: AppState) {
  const {
    localStorage: {
      debugFlags: { alwaysAllowAirplane }
    },
    killswitch: { airplaneAllowList },
    product: { colorId, detail: { sizing } = {} }
  } = store;
  return { airplaneAllowList, alwaysAllowAirplane, colorId, sizing };
}

export default connect(mapStateToProps)(SwankySwatchBox);
