import React, { useState } from 'react';

import Tooltip from 'components/common/Tooltip';
import useMartyContext from 'hooks/useMartyContext';
import type { SocialIconProduct } from 'reducers/detail/productDetail';
import FacebookShareV2 from 'components/productdetail/sharing/FacebookShareV2';
import LinkShare from 'components/productdetail/sharing/LinkShare';
import PinterestShareV2 from 'components/productdetail/sharing/PinterestShareV2';
import TwitterShareV2 from 'components/productdetail/sharing/TwitterShareV2';
import ProductUtils from 'helpers/ProductUtils';
import UtilityStrokeShareMediumIcon from 'tailwind/components/Icons/UtilityStrokeShareMediumIcon';

import css from 'styles/components/common/shareSocialLinks.scss';

export interface ShareSocialLinksProps {
  productDetail: SocialIconProduct;
  onSharingButtonClick: (linkName: string) => void;
}

const ShareSocialLinks = (props: ShareSocialLinksProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const {
    productDetail: { fbAppId, linksProductContent, productId, styleId },
    onSharingButtonClick
  } = props;
  const { testId } = useMartyContext();

  const showLinkClicked = () => {
    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 2000);
  };

  const onShareSocialClick = (toolTipOnClick: () => void) => {
    if (navigator.share) {
      navigator.share({
        url: `${linksProductContent.link}`
      });
    } else {
      toolTipOnClick();
    }
    !isContentVisible && ProductUtils.generateShareIconClickEvent(productId, styleId);
    setIsContentVisible(true);
  };

  return (
    <Tooltip
      direction="bottom"
      wrapperClassName={css.tooltipWrapper}
      contentClassName={css.tooltipContent}
      tooltipClassName={css.tooltip}
      ariaLabel="Social Media Share Icon, (opens drop down)"
      dataTestId="shareIconButton"
      clickToOpen={true}
      onClick={onShareSocialClick}
      isLinkCopied={isLinkCopied}
      setIsContentVisible={setIsContentVisible}
      content={
        <div className={css.sharingV2}>
          <div className={css.sharingIconsV2} data-test-id={testId('shareSectionV2')}>
            <ul>
              <li className={css.links}>
                <FacebookShareV2
                  className={css.facebookV2}
                  appId={fbAppId}
                  product={linksProductContent}
                  onClick={() => onSharingButtonClick('facebook')}
                  iconFillColor={'#4267B2'}
                  iconName="Facebook"
                />
              </li>
              <li className={css.links}>
                <TwitterShareV2
                  className={css.twitterV2}
                  product={linksProductContent}
                  onClick={() => onSharingButtonClick('twitter')}
                  iconFillColor={'#1DA1F2'}
                  iconName="Twitter"
                />
              </li>
              <li className={css.links}>
                <PinterestShareV2
                  className={css.pinterestV2}
                  product={linksProductContent}
                  onClick={() => onSharingButtonClick('pinterest')}
                  iconFillColor={'#E60023'}
                  iconName="Pinterest"
                />
              </li>
              <li className={css.links}>
                <LinkShare
                  className={css.link}
                  product={linksProductContent}
                  onClick={() => onSharingButtonClick('copy')}
                  showLinkClicked={showLinkClicked}
                  isLinkCopied={isLinkCopied}
                  iconFillColor={'#888'}
                  iconName="Copy Link"
                />
              </li>
            </ul>
          </div>
        </div>
      }
    >
      <UtilityStrokeShareMediumIcon size={24} />
    </Tooltip>
  );
};

export default ShareSocialLinks;
