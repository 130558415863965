import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { LegendData } from 'components/productdetail/swankyswatch/Legend';

import css from 'styles/components/productdetail/swankyswatch/LegendIndicator.scss';

export enum Legends {
  New = 'New Color'
}

const legendsClassMap = new Map<Legends, string>([[Legends.New, css.legendNew]]);

export const getLegend = (legend: Legends): LegendData => {
  switch (legend) {
    case Legends.New:
      return { text: Legends.New, className: legendsClassMap.get(Legends.New) };
    default:
      return { text: '', className: '' };
  }
};

export interface LegendIndicatorProps {
  legendType: Legends;
}

const LegendIndicator = ({ legendType }: LegendIndicatorProps) => {
  const { testId } = useMartyContext();
  const legend = getLegend(legendType);

  return <span data-test-id={testId('LegendIndicator')} className={cn(legend.className, css.legendIndicator)} />;
};

export default LegendIndicator;
