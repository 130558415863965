import type { AirplaneCache } from 'types/AirplaneCache';
import type { ProductBundle } from 'types/cloudCatalog';

/**
 * Is airplane seat sizing enabled for this product?
 *
 * @param airplaneAllowList symphony killswitch (airplaneAllowList1,
 *                          airplaneAllowList2, etc.)
 * @param productType `defaultProductType` from the product bundle
 * @returns true or false
 */
export function isProductAirplaneEnabled(airplaneAllowList: string[], productType: ProductBundle['defaultProductType']) {
  return airplaneAllowList?.includes(productType);
}

/**
 * Should we show airplane seat sizing?
 *
 * This function returns true if all of the conditions noted below are true:
 * - the given productType is in the symphony-controlled airplaneAllowList
 * - we built airplane data for this product (airplaneCache !== undefined)
 *
 * @param airplaneAllowList symphony killswitch (airplaneAllowList1,
 *                          airplaneAllowList2, etc.)
 * @param alwaysAllowAirplane a debugging flag that wildcards the allow list
 * @param productType `defaultProductType` from the product bundle
 * @param airplaneCache result of `makeAirplaneCache`
 * @returns true or false
 */
export function showAirplaneSizing(
  airplaneAllowList: string[],
  alwaysAllowAirplane: boolean,
  productType: string,
  airplaneCache: AirplaneCache | undefined
): airplaneCache is AirplaneCache {
  if (!airplaneCache) {
    return false;
  }
  const enabledForProduct = isProductAirplaneEnabled(airplaneAllowList, productType);
  return enabledForProduct || alwaysAllowAirplane;
}
