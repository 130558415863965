import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/productdetail/swankyswatch/Legend.scss';

export interface LegendData {
  text: string;
  className: string | undefined;
}

export interface LegendProps {
  legends: LegendData[];
}

const Legend = ({ legends }: LegendProps) => {
  const { testId } = useMartyContext();

  if (!legends || legends.length === 0) {
    return null;
  }

  return (
    <ul data-test-id={testId('Legend')} className={css.legend}>
      {legends.map(({ text, className }) => {
        {
          return (
            <li key={text} className={cn(className, css.legendSpacing)}>
              {text}
            </li>
          );
        }
      })}
    </ul>
  );
};

export default Legend;
