import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import { buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
import ProductUtils from 'helpers/ProductUtils';
import BadgeUtils from 'helpers/BadgeUtils';
import useMartyContext from 'hooks/useMartyContext';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import type { InfluencerState } from 'types/influencer';
import { InfluencerStatus } from 'types/influencer';
import ShareSocialLinks from 'components/common/ShareSocialLinks';
import type { SelectedSizing } from 'types/product';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';
import { selectZsoBrandLink } from 'selectors/product';
import ProductGender from 'components/common/ProductGender';

import css from 'styles/components/productdetail/productName.scss';

interface Props {
  product: FormattedProductBundle;
  className?: string;
  colorId: string | undefined;
  brandNameClass?: string;
  productNameClass?: string;
  selectedSizing?: SelectedSizing;
  obfuscatedCustomerId?: string;
  influencer?: InfluencerState;
  showSocialLinks?: Boolean;
  productCardGenderDisplay?: string;
  isProductTypeShoesOrClothing?: boolean;
  isPdpPaperCutsFeatureEnabled?: boolean;
}

export const ProductNameContents = (props: Props) => {
  const {
    brandNameClass,
    className,
    colorId,
    selectedSizing,
    showSocialLinks,
    product,
    productNameClass,
    obfuscatedCustomerId,
    influencer,
    productCardGenderDisplay,
    isProductTypeShoesOrClothing
  } = props;
  const { brandName, productName, defaultProductType, styles, productId, defaultImageUrl, sizing } = product;
  const isNotGiftCard = !ProductUtils.isGiftCard(defaultProductType);
  const {
    testId,
    marketplace: { links, isInfluencerProgramEnabled }
  } = useMartyContext();
  const isCanonicalColor = colorId === styles[0]!.colorId;
  const urlForStructuredData = buildSeoProductUrl(product, isCanonicalColor ? undefined : colorId);
  // We keep the space after the brand name for screen readers reading the brand then the product name.

  const brandLink = useSelector(selectZsoBrandLink) || product.seo?.breadcrumbs?.brand || '';
  const { fbAppId } = links.sharing;
  const { status: influencerStatus, influencerToken } = influencer || {};
  const style = ProductUtils.getStyleByColor(styles, colorId);
  const link =
    isInfluencerProgramEnabled && influencerStatus === InfluencerStatus.ACTIVE && influencerToken
      ? ProductUtils.getInfluencerSharingButtonLink(productId, influencerToken, colorId)
      : ProductUtils.getSharingButtonLink(productId, colorId);

  const linksProductContent = {
    link: link,
    name: `${brandName} ${productName}`,
    style: style.color,
    image: defaultImageUrl
  };

  const microDataGender = productCardGenderDisplay ? `${productCardGenderDisplay} ` : '';

  const asin = ProductUtils.getSelectedStyleStockBySize([], sizing, selectedSizing, style.stocks)?.asin || null;

  const onSharingButtonClick = (linkName: string) => {
    if (isInfluencerProgramEnabled && influencerStatus === InfluencerStatus.ACTIVE && obfuscatedCustomerId && influencerToken) {
      ProductUtils.generateShareLinkAmethystEvent(linkName, obfuscatedCustomerId, productId, influencerToken, colorId);
    }
    ProductUtils.generateShareLinkEvent(linkName, asin, PRODUCT_PAGE);
  };

  const { styleId } = style;
  const shareIconProps = { fbAppId, linksProductContent, productId, styleId };

  const { badges } = style;

  return (
    <div className={className}>
      {isNotGiftCard && (
        <span className={cn(css.brandName, brandNameClass)} itemProp="brand" itemScope itemType="http://schema.org/Brand">
          <Link
            to={brandLink}
            itemProp="url"
            data-test-id={testId('pdpBrandName')}
            aria-label={`${BadgeUtils.getProductNameBadgeLabel(badges)}${brandName}`}
          >
            <span itemProp="name">{brandName}</span>
          </Link>{' '}
          {showSocialLinks && <ShareSocialLinks productDetail={shareIconProps} onSharingButtonClick={onSharingButtonClick} />}
        </span>
      )}
      <span className={cn(css.productName, productNameClass)} data-test-id={testId('pdpProductName')}>
        {productName}
      </span>
      {productCardGenderDisplay && isProductTypeShoesOrClothing && (
        <div className="md:text-center lg:text-left">
          {' '}
          <ProductGender className="text-base" gender={productCardGenderDisplay} />
        </div>
      )}
      <meta itemProp="name" content={`${microDataGender}${brandName} ${productName}`} />
      <meta itemProp="url" content={urlForStructuredData} />
    </div>
  );
};

const ProductName = ({
  className,
  colorId,
  selectedSizing,
  product,
  showSocialLinks,
  obfuscatedCustomerId,
  influencer,
  productCardGenderDisplay,
  isProductTypeShoesOrClothing,
  isPdpPaperCutsFeatureEnabled
}: Props) => (
  <h1
    className={cn(
      css.container,
      {
        [css.breakLongProductName]: product.productName.length > 45
      },
      { [css.paperCuts]: isPdpPaperCutsFeatureEnabled }
    )}
  >
    <ProductNameContents
      showSocialLinks={showSocialLinks}
      selectedSizing={selectedSizing}
      product={product}
      className={className}
      colorId={colorId}
      obfuscatedCustomerId={obfuscatedCustomerId}
      influencer={influencer}
      productCardGenderDisplay={productCardGenderDisplay}
      isProductTypeShoesOrClothing={isProductTypeShoesOrClothing}
    />
  </h1>
);

export default ProductName;
