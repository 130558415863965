import React from 'react';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { ProductImage, ProductStyle } from 'types/cloudCatalog';
import type { Legends } from 'components/productdetail/swankyswatch/LegendIndicator';
import LegendIndicator from 'components/productdetail/swankyswatch/LegendIndicator';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { selectIsFeaturePdpPaperCuts } from 'selectors/features';

import css from 'styles/components/productdetail/swankyswatch/SwankyStyleSwatch.scss';

const TOP_IMAGE_BRANDS = ['Havaianas', 'Havaianas Kids'];

interface Props {
  isSelected: boolean;
  isUnavailable: boolean;
  onColorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  productType: string;
  style: ProductStyle;
  brandName?: string;
  namespace: string;
  legendIndicator?: Legends;
}

export default function SwankyStyleSwatch({
  isUnavailable,
  onColorChange,
  productType,
  isSelected,
  style,
  brandName,
  namespace,
  legendIndicator
}: Props) {
  const { color: colorName, images = [], styleId, isNew } = style;
  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);
  const isPdpPaperCutsFeatureEnabled = useSelector(selectIsFeaturePdpPaperCuts);

  const { testId } = useMartyContext();
  const styleSwatchImageId = chooseStyleSwatchImageId(images, productType, brandName);
  const imageUrl = styleSwatchImageId ? `https://m.media-amazon.com/images/I/${styleSwatchImageId}.AC_SS144.jpg` : undefined;
  const uniqueId = `${styleId}-${namespace}`;

  return (
    <>
      <input
        type="radio"
        name="colorSelect"
        className={cn('sr-only', css.input)}
        onChange={onColorChange}
        data-color-name={colorName}
        data-style-id={styleId}
        data-is-new={isNew}
        id={uniqueId}
      />
      <label
        htmlFor={uniqueId}
        aria-current={isSelected}
        className={cn(
          css.button,
          {
            [css.unavailable]: isUnavailable,
            [css.selected]: isSelected,
            [css.oosCefi]: isUnavailable && hydraCefi
          },
          { [css.paperCutDisabledOverlay]: isPdpPaperCutsFeatureEnabled && isUnavailable }
        )}
        data-test-id={testId(`styleSwatch-${styleId}`)}
      >
        <span className="sr-only">{colorName}</span>
        <div>
          <img src={imageUrl} alt="" aria-hidden className={cn(css.image, { ['p-1']: isPdpPaperCutsFeatureEnabled })}></img>
          {legendIndicator !== undefined && <LegendIndicator legendType={legendIndicator} />}
        </div>
      </label>
    </>
  );
}

function chooseStyleSwatchImageId(images: ProductImage[], productType: string, brandName?: string) {
  // use the "left side" angle for shoes, but check certain brands don't render the left side
  if (productType && productType.toLowerCase() === 'shoes' && !(brandName && TOP_IMAGE_BRANDS.includes(brandName))) {
    const leftImageInfo = images.find(imageInfo => imageInfo.type === 'LEFT');
    if (leftImageInfo) {
      return leftImageInfo.imageId;
    }
  }
  return images[0]?.imageId;
}
