import { cn } from 'helpers/classnames';
import { PRICE } from 'common/regex';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { AFTERPAY_MAXIMUM, AFTERPAY_MINIMUM } from 'constants/appConstants';
import ProductUtils from 'helpers/ProductUtils';
import useMartyContext from 'hooks/useMartyContext';
import type { ProductStyle } from 'types/cloudCatalog';
import FinalSale from 'components/common/FinalSale';
import Tag from 'tailwind/components/Tag/Tag';

import css from 'styles/components/productdetail/price.scss';

interface Props {
  productStyle: ProductStyle;
  percentOffText: string;
  showPercentOffBanner: boolean;
  className?: string;
  small?: boolean;
  defaultProductType?: string;
  isAvailable?: boolean;
  isLowestRecentPrice?: boolean;
}

function getPriceComponents(priceString: string): string[] | undefined {
  const match = priceString.match(PRICE);
  if (!match) {
    return undefined;
  }
  if (!match[2]) {
    return [match[1]!];
  }
  return [match[1]!, match[2]];
}

export const Price = ({
  className,
  productStyle,
  small,
  defaultProductType,
  isAvailable,
  isLowestRecentPrice,
  percentOffText,
  showPercentOffBanner
}: Props) => {
  const {
    testId,
    marketplace: {
      checkout: { allowAfterPay }
    }
  } = useMartyContext();
  const { productUrl, finalSale, price, originalPrice } = productStyle;
  const showDiscount = ProductUtils.isStyleOnSale(productStyle);
  const mainPrice = price || originalPrice;
  const components = getPriceComponents(mainPrice);
  const dollarSign = components ? '$' : '';
  const priceDollarValue = components ? components[0] : mainPrice;
  const priceDollarValueWithoutComma = priceDollarValue?.replace(/,/g, '');
  const priceCentsValue = components?.[1] ? components[1] : '';
  const structuredDataPrice = priceDollarValue + (priceCentsValue ? `.${priceCentsValue}` : '');
  const isProductAfterpayEligible =
    defaultProductType !== 'Gift Cards' && +priceDollarValueWithoutComma! >= AFTERPAY_MINIMUM && +priceDollarValueWithoutComma! <= AFTERPAY_MAXIMUM;
  const percentOff = ProductUtils.getPercentOff(productStyle);
  const shouldShowTags = (showDiscount && isLowestRecentPrice) || (showPercentOffBanner && percentOff > 0);
  const shouldShowPercentOff = showPercentOffBanner;
  const shouldShowLowestPriceIn30Days = isLowestRecentPrice;

  return (
    <div className={cn(className, { [css.small]: small }, css.main)}>
      <div className={css.container} itemProp="offers" itemScope itemType="https://schema.org/Offer" data-test-id={testId('priceOffersContainer')}>
        {shouldShowTags && (
          <div className="flex flex-wrap gap-x-1 gap-y-1">
            {shouldShowPercentOff && (
              <Tag variant="blue 400" size="small" data-test-id={testId('percentOff')}>
                {percentOff}% {percentOffText}
              </Tag>
            )}
            {shouldShowLowestPriceIn30Days && (
              <Tag variant="blue 400" size="small" data-test-id={testId('lowestPrice30Days')}>
                Lowest Price in 30 days
              </Tag>
            )}
          </div>
        )}
        {/* aria-label tells price to screen reader */}
        <span
          aria-label={mainPrice}
          className={cn(css.price, { [css.sale]: showDiscount })}
          data-test-id={testId('pdpProductPrice')}
          itemProp="price"
          content={structuredDataPrice}
        >
          {/*
            hide the fancy-formatted price (superscript dollar sign and cents)
            because the styling alone causes it to be broken up. a screen
            reader will see "$\n24\n.\n99" for a price of "$24.99"
          */}
          <span aria-hidden="true">
            {/* TODO refactor all this into a StylizedPrice component (working name) */}
            <span className={css.dollarSign} itemProp="priceCurrency" content="USD">
              {dollarSign}
            </span>
            {priceDollarValue}
            {priceCentsValue && (
              <>
                <span className={css.priceDecimalPoint}>.</span>
                <span className={css.priceCentsValue}>{priceCentsValue}</span>
              </>
            )}
          </span>
          {showDiscount && (
            <span className={css.discount}>
              <span className={css.originalPrice} data-test-id={testId('pdpOriginalPrice')}>
                <span className={css.msrpLabel}>
                  <abbr title="Manufacturer's Suggested Retail Price">MSRP</abbr>:{' '}
                </span>
                <span className={cn(css.msrpText, css.lineThrough)} data-test-id={testId('msrpPrice')}>
                  {originalPrice}
                </span>
              </span>
            </span>
          )}
          {finalSale && <FinalSale className={css.finalSale} />}
        </span>
        {isAvailable ? (
          <meta itemProp="availability" content="https://schema.org/InStock" />
        ) : (
          <meta itemProp="availability" content="https://schema.org/OutOfStock" />
        )}
        <meta itemProp="url" content={productUrl} />
      </div>

      {!!components && allowAfterPay && (
        <div className={css.afterpayPlacement} data-test-id={testId('afterpay')}>
          <afterpay-placement
            data-locale="en_US"
            data-currency="USD"
            data-is-eligible={isProductAfterpayEligible}
            data-amount={`${priceDollarValue}${priceCentsValue && '.'}${priceCentsValue}`}
            data-logo-type="lockup"
            data-size="sm"
            data-modal-theme="white"
          />
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary('Price', Price);
